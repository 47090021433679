import Button from '@material-ui/core/Button';
import Container from "@material-ui/core/Container";
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import React from "react";

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: 'scroll'
    },
    jsonTable: {
        '& table': { tableLayout: 'fixed' },
        '& td': { wordWrap: 'break-word' }
    },
    imageList: {
        width: 900,
        height: 600,
        justify:"center"
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    }
}));

export default function NFT(props) {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleOpen}>
                NFT
            </Button>

            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={handleClose}
                className={classes.modal}
            >
                <div className={classes.paper}>
                    <Container className={classes.jsonTable} maxWidth="lg">
                    <ImageList rowHeight={600} gap={5} className={classes.imageList}>
                        <ImageListItem key={props.data.signedToken.tokenBody.tokenId}>
                            <img src={props.data.signedToken.tokenBody.content[0]} alt={props.data.signedToken.tokenBody.tokenId} />
                            <ImageListItemBar
                                title={props.data.signedToken.tokenBody.content[1]}
                                subtitle={<span>Author: {props.data.signedToken.tokenBody.content[2]}</span>}
                            />
                        </ImageListItem>
                    </ImageList>
                    </Container>
                </div>
            </Modal>
        </div >
    );
}