import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { decode } from "base64-arraybuffer";
import clsx from 'clsx';
import protobuf from "protobufjs";
import React, { useEffect, useState } from 'react';
import Deposits from './Deposits';
import LastEventsList from "./LastEventsList";
import NFTs from "./NFTs";
import proto from "./signedToken.proto";


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="">

            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: '100%',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 150,
    },
}));


export default function Dashboard() {

    const classes = useStyles();
    const [open, setOpen] = useState(true);


    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const [lastBlockNumHight, setLastBlockNumHight] = useState(0)

    const [lastBlockNumLow, setLastBlockNumLow] = useState(0)

    const [txCounter, setTxCounter] = useState(0)

    const [tokenTypeCounter, setTokenTypeCounter] = useState(0)

    const [tokenCounter, setTokenCounter] = useState(0)

    const [txEvents, setTxEvents] = useState([])

    const [blockList, setblockList] = useState([])

    const [tokenList, setTokenList] = useState([])

    const [listenerStatus, setListenerStatus] = useState(false)

    const [listenerBtn, setListenerBtn] = useState("Начать прослушивание новых блоков")

    const [listenerTimer, setListenerTimer] = useState(undefined)

    const [currentSyncBlock, setCurrentSyncBlock] = useState(undefined)

    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    async function decodeSignedToken(signedToken) {

        let root = await protobuf.load(proto)
        var SignedToken = root.lookupType("models.SignedToken");
        //var buffer = decode("CrkBCi4yYzMzMnoydDMyMm4zNzJxMnQzNjJxMnAzMjJ6Mm4ydjJwMzgydDE5MWMybjFlEh5ORlRfdGVzdF92MEdhYzJhWEIzQl9uazVsZWtjaGMaVGh0dHBzOi8vcGF1bGJvdC5zMy5ldS1jZW50cmFsLTEuYW1hem9uYXdzLmNvbS9JbWFnZXMvOTk5L2NhdC9jYXQvank0bmpzczlpaHVqbjA3LnBuZxoLU2JlciBLaXR0aWUaBFNiZXISAQA=");

        var buffer = decode(signedToken);
        var message = SignedToken.decode(new Uint8Array(buffer));
        var object = SignedToken.toObject(message, {
            longs: String,
            enums: String,
            bytes: String,
        });
        return object
    }

    async function getPropFromJSON(json) {
        let exp = new RegExp("\"signedToken\":([^,}]*)");
        let parsed = JSON.stringify(json);
        let parsedExp = parsed.match(exp);

        if (parsedExp) {
            let decodedST = parsedExp[1].replace("\"", "").replace("\"", "");
            let decodedSignedtoken = await decodeSignedToken(decodedST)
            let stringifySignedToken = JSON.stringify(decodedSignedtoken);
            let replacerValue = `"signedToken":${stringifySignedToken}`;
            let result = parsed.replace(parsedExp[0], replacerValue);
            let signedTokenParsed = JSON.parse(`{${replacerValue}}`)
            let nftToken = {};
            if ((signedTokenParsed.signedToken.tokenBody.content.lenght != 0) && (signedTokenParsed.signedToken.tokenBody.content[0].includes("http"))) {
                nftToken = signedTokenParsed;
            }
            return [JSON.parse(result), nftToken]
        }
        return [json, {}]
    }

    async function blockParser(blockDelta, lastBlockNum) {
        let blockList = [];
        for (let i = 0; i <= blockDelta; i++) {
            const newBlockNum = lastBlockNum - i
            const res = await fetch(`https://srv.blch.ru/block-events?blockNumber=${newBlockNum}`);
            const eventList = await res.json();

            for (const [key, value] of Object.entries(eventList)) {
                if (value.length !== 0) {
                    value.forEach(async (element) => {
                        if (key === "tokenTypesRegistered") {
                            setTokenTypeCounter(counter => counter + 1)
                        }
                        if (key === "tokensIssued") {
                            element.event.token.forEach(async (token) => {
                                let signedToken = await decodeSignedToken(token.signedToken);
                                if ((signedToken.tokenBody.content.lenght != 0) && (signedToken.tokenBody.content[0].includes("http"))) {
                                    setTokenList(arr => [...arr, signedToken])
                                }
                                setTokenCounter(counter => counter + 1);
                            })
                        }

                        let tx = element.txId
                        setTxCounter(counter => counter + 1);
                        let parsedEvent = await getPropFromJSON(element.event);
                        blockList.push([newBlockNum, tx, key, parsedEvent[0], parsedEvent[1]])
                    })
                }
            }
        }
        return Promise.resolve(blockList);
    }

    async function fetchData() {
        const res = await fetch('https://srv.blch.ru/block-number');
        const blockNum = await res.json();
        setCurrentSyncBlock(_ => blockNum);
    }

    useEffect(() => {
        let timer;
        if (listenerStatus) {
            setListenerBtn(_ => "Закончить прослушивание новых блоков")
            timer = setInterval(() => {
                fetchData()
            }, 5000);
            setListenerTimer(_ => timer);
        }
        else {
            setListenerBtn(_ => "Начать прослушивание новых блоков")
            setListenerTimer(_ => undefined);
            clearTimeout(timer);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [listenerStatus])

    async function syncBlockData() {
        if (listenerStatus) {
            console.log("call syncblock")
            let blockDelta = currentSyncBlock - lastBlockNumHight
            let blockList = await blockParser(blockDelta, currentSyncBlock);
            setTxEvents(prev => { return [...blockList, ...prev] });
            setLastBlockNumHight(_ => currentSyncBlock);
        }
    }

    useEffect(() => {
        syncBlockData()
    }, [currentSyncBlock]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        CNFT Block Explorer
                    </Typography>
                </Toolbar>
            </AppBar>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        {/* Blocks */}
                        <Grid item xs={12} md={4} lg={3}>
                            <Paper className={fixedHeightPaper}>
                                <Deposits title="Последний блок" data={lastBlockNumHight} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Paper className={fixedHeightPaper}>
                                <Deposits title="Количество транзакций" data={txCounter} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Paper className={fixedHeightPaper}>
                                <Deposits title="Типов токенов" data={tokenTypeCounter} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Paper className={fixedHeightPaper}>
                                <Deposits title="Токенов выпущено" data={tokenCounter} />
                                <NFTs data={tokenList} />
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Button variant="contained" color="primary" onClick={() => {
                                    setListenerStatus((prev => !prev));
                                }
                                }>{listenerBtn}</Button><br></br>
                                <Button variant="contained" color="primary" onClick={async () => {
                                    const res = await fetch('https://srv.blch.ru/block-number');
                                    const blockNum = await res.json();

                                    if (lastBlockNumHight !== blockNum) {
                                        let blockDelta = blockNum - lastBlockNumHight
                                        if (blockDelta > 50) {
                                            blockDelta = 50
                                        }
                                        let blockList = await blockParser(blockDelta, blockNum);
                                        setTxEvents(arr => [...arr, ...blockList])
                                    }
                                    setLastBlockNumHight(blockNum);
                                }
                                }>Загрузить последние 50 транзакций</Button>
                                <LastEventsList data={txEvents} />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Copyright />
                    </Box>
                </Container>
            </main>
        </div>
    );
}