import React from "react";
import Dashboard from "./Dashboard";
import { Transactions } from "../Transactions";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import {TokenBrowser} from "./TokenBrowser";
/* global BigInt */
// export interface Props {
//     /** The user's name */
//     name: string;
//     /** Should the name be rendered in bold */
//     priority?: boolean
//   }


export const Main: PureComponentWithProps<DashboardProps> = (props: DashboardProps) => {

    // const [wallet, setWallet] = useState(undefined)

    // const [identity, setIdentity] = useState(undefined)

    // const loading = false

    // const [tokens, setTokens] = useState([])

    // const [tokenTypes, setTokenTypes] = useState([{}])

    // const [offers, setOffers] = useState([])

    // const [events, setEvents] = useState([])

    // const updateTokenTypes = async (wallet) => {
    //     try {
    //         const queryResult = await wallet.listTokenTypes
    //         const tokenTypeList = queryResult
    //             .map(r => {
    //                 let typeId = r.theType.typeId
    //                 let meta = r.theType.tokenMeta.map(m => (
    //                     {
    //                         name: m.tokenMetaName,
    //                         type : m.TokenMetaType
    //                     }
    //                 ))
    //                 return { id: typeId, title: typeId, owned: r.owned, meta: meta }
    //             })
    //             // .filter(e => (e.owned))
    //         console.log("tokenTypeList", JSON.stringify(tokenTypeList))
    //         setTokenTypes(tokenTypeList)


    //         return tokenTypeList
    //     } catch (err) {
    //         console.log('failed to fetch token types', err);
    //         throw err
    //     }
    // };

    return (
        <Router>
            <div>
                <Switch>
                    <Route path="/explorer/tx">
                        <TokenBrowser />
                    </Route>
                    <Route path="/">
                        <Dashboard />
                    </Route>
                </Switch>
            </div>
        </Router>
    )


}