import React, {useEffect, useLayoutEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
} from "react-router-dom";
import protobuf from "protobufjs";
import proto from "./signedToken.proto";
import {decode} from "base64-arraybuffer";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import logo from "../SberLogo-20.svg"
import HelpIcon from '@material-ui/icons/Help';
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import {saveAs} from 'file-saver'

import parse from 'html-react-parser';
import Box from "@material-ui/core/Box";

export function TokenBrowser() {
    let {path, url} = useRouteMatch();

    return (<div>
        <Switch>
            <Route exact path={path}>
                <h3> here be transactions</h3>
            </Route>
            <Route path={`${path}/:txId`}>
                <NFTToken/>
            </Route>
        </Switch>
    </div>)
}


const useStyles = makeStyles((theme) => ({
    root: {},
    fixedHeight: {
        height: 600,
    },
    card: {
        maxWidth: 500,
        flexGrow: 1,
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    media: {
        height: 400,
        backgroundSize: "contain",
        //paddingTop: '56.25%', // 16:9
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        textAlign: 'left',
        color: theme.palette.text.secondary,
        wordWrap: "break-word",
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: '100%',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    title: {
        flexGrow: 1,
    },
    logo: {
        maxWidth: 40,
        marginRight: '10px'
    },
    summary: {
        overflow: 'hidden'
    }
}));

export default function NFTToken() {
    const {txId} = useParams();
    const classes = useStyles();
    const [tokenData, setTokenData] = useState({})
    const [isLoading, setLoading] = useState(true);
    const [loadingPhrase, setLoadingPhrase] = useState('Loading...');
    const [imageObjectURL, setImageObjectURL] = useState();
    const [imageBlob, setImageBlob] = useState();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const getSavedImage = () => {
        const urlPath = tokenData.tokenBody.content[0]
        saveAs(imageBlob, urlPath.substring(urlPath.lastIndexOf("/") + 1, urlPath.length))
    }

    async function getImage(result) {
        fetch(result.tokenBody.content[0]).then(async function (response) {
            if (response.ok) {
                const blob = await response.blob();
                setImageObjectURL(URL.createObjectURL(blob));
                setImageBlob(blob);
                setLoading(false);}
            else {
                setLoadingPhrase(`К сожалению, ресурс ${result.tokenBody.content[0]} недоступен, попробуйте позже.`);
            }

        }).catch(function (err) {
            setLoadingPhrase(`К сожалению, ресурс ${result.tokenBody.content[0]} недоступен, попробуйте позже.`);
        })
    }

    async function decodeSignedToken(signedToken) {

        let root = await protobuf.load(proto)
        var SignedToken = root.lookupType("models.SignedToken");
        //var buffer = decode("CrkBCi4yYzMzMnoydDMyMm4zNzJxMnQzNjJxMnAzMjJ6Mm4ydjJwMzgydDE5MWMybjFlEh5ORlRfdGVzdF92MEdhYzJhWEIzQl9uazVsZWtjaGMaVGh0dHBzOi8vcGF1bGJvdC5zMy5ldS1jZW50cmFsLTEuYW1hem9uYXdzLmNvbS9JbWFnZXMvOTk5L2NhdC9jYXQvank0bmpzczlpaHVqbjA3LnBuZxoLU2JlciBLaXR0aWUaBFNiZXISAQA=");

        var buffer = decode(signedToken);
        var message = SignedToken.decode(new Uint8Array(buffer));
        var object = SignedToken.toObject(message, {
            longs: String,
            enums: String,
            bytes: String,
        });
        return object
    }

    async function fetchData() {
        const response = await fetch(`https://srv.blch.ru/block-events?blockNumber=${txId}`);
        const responseContent = await response.json()
        const event = responseContent.tokensExchanged[0].event;
        const [tokenId, changes] = Object.entries(event.changes)[0]

        console.log(changes)

        const parsedToken = await decodeSignedToken(changes.signedToken)

        const result = {
            "blockNum": txId,
            "txId": responseContent.tokensExchanged[0].txId,
            "dealId": event.dealId,
            "owner": changes.tokenOwner.keys[0], ...parsedToken
        }

        setTokenData(_ => result)
        await getImage(result)
    }

    useEffect(() => {
        fetchData()
    }, []);

    if (isLoading) {
        return <div className="App">{loadingPhrase}</div>;
    }

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar position="absolute" className={clsx(classes.appBar, classes.appBarShift)}>
                <Toolbar>
                    <img src={logo} className={classes.logo}/>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        Sber Blockchain
                    </Typography>
                </Toolbar>
            </AppBar>
            <main className={classes.content}>
                <div className={classes.appBarSpacer}/>
                <Container maxWidth="md" className={classes.container}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Paper className={fixedHeightPaper}>
                                <Card>
                                    <CardActionArea>
                                        <CardMedia
                                            className={classes.media}
                                            alt={tokenData.tokenBody.content[1]}
                                            image={imageObjectURL}
                                            title={tokenData.tokenBody.content[1]}
                                            descriprion={tokenData.tokenBody.content[3]}
                                        />
                                    </CardActionArea>
                                </Card>
                                <Box pt={1}>
                                    <Button variant="outlined" color="primary" onClick={getSavedImage} fullWidth={true}>Скачать изображение</Button>
                                    <Typography variant="subtitle2" component="p">
                                        Изображение сохраняется в приложение Файлы (не в Фото)
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper className={fixedHeightPaper}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {tokenData.tokenBody.content[1]}
                                </Typography>
                                <Typography variant="subtitle1" component="p">
                                    <strong>Автор: </strong> {tokenData.tokenBody.content[2]}
                                </Typography>
                                <Typography variant="subtitle1" component="p">
                                    <strong>Описание: </strong> {parse(tokenData.tokenBody.content[3])}
                                </Typography>
                                <Typography variant="subtitle1" component="p">
                                    <strong>Id токена: </strong> {tokenData.tokenBody.tokenId}
                                </Typography>
                                <Typography variant="subtitle1" component="p">
                                    <strong>Id транзакции: </strong> {tokenData.txId}
                                </Typography>
                                <Typography variant="subtitle1" component="p">
                                    <strong>Id блока: </strong> {tokenData.blockNum}
                                </Typography>
                            </Paper>

                        </Grid>
                    </Grid>
                </Container>
            </main>
        </div>
    )
}
